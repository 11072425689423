import './style.css'
import * as THREE from 'three'
import * as dat from 'lil-gui'
import gsap from 'gsap'

import gradientVertexShader from './shaders/gradient/vertex.glsl'
import gradientFragmentShader from './shaders/gradient/fragment.glsl'


import particlesVertexShader from './shaders/particles/vertex.glsl'
import particlesFragmentShader from './shaders/particles/fragment.glsl'

import pMenuVertexShader from './shaders/particlesmenu/vertex.glsl'
import pMenuFragmentShader from './shaders/particlesmenu/fragment.glsl'


import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'


import servicesFragmentShader from './shaders/services/fragment.glsl'
import servicesVertexShader from './shaders/services/vertex.glsl'

/**
 * Base
 */
// Debug
//const gui = new dat.GUI({ width: 340 })
const debugObject = {}

let renderer = null;
let rendererTwo =  null;
let rendererServices =  null;
let rendererAbout =  null;

// Canvas
const canvas = document.querySelector('canvas.webgl')
const canvasTwo = document.querySelector('canvas.menugl')
const canvasServices = document.querySelector('canvas.servicesgl')
const canvasAbout = document.querySelector('canvas.aboutgl')
// Scene
const scene = new THREE.Scene()
const sceneTwo = new THREE.Scene()
const sceneServices = new THREE.Scene()
const sceneAbout = new THREE.Scene()
//ideas
//http://webglplayground.net/
//
//
//
//
//
//

/**
 * Cursor
 */
 const cursor = {}
 cursor.x = 0
 cursor.y = 0 

window.addEventListener('mousemove', (event) =>
{
   cursor.x = ( event.clientX / sizes.width ) 
   cursor.y = 1 - ( event.clientY / sizes.height ) 
   //console.log(cursor)
})

/**
 * Sizes
 */
 const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}




/**
 * Menu Canvas
 */
 const gltfLoader = new GLTFLoader()


 const particlesMaterial = new THREE.ShaderMaterial({
    vertexShader: pMenuVertexShader,
    fragmentShader: pMenuFragmentShader,
    uniforms: {
         uColor1: { value: new THREE.Color('#ffffff')},
         uColor2: { value: new THREE.Color('#ffffff')},
         uTime: { value: 0 },
         uSize: { value: 35 }, 
         uMouse: { value: new THREE.Vector2(cursor.x, cursor.y) },
    },
    transparent: true,
    depthTest: false, 
    depthWrite: false, 
    //blending: THREE.AdditiveBlending
})


gltfLoader.load(
    '/models/bigG2.glb',
    (gltf) =>
    {
        /**
         * Original Mesh
         */
        const mesh = gltf.scene.children[0]
        /**
         * Geometry Mesh
         */
       
        const geometry = mesh.geometry

        /**
         * Particles Geometry
         */

        const sampler = new MeshSurfaceSampler(mesh).build()
        const numParticles = 10000
        const particlesGeometry = new THREE.BufferGeometry()
        const particlesPosition = new Float32Array(numParticles * 3)

        const particlesRandomness = new Float32Array(numParticles * 3)



        for (let i = 0; i < numParticles; i++ ) {
            const newPosition = new THREE.Vector3()
            sampler.sample(newPosition)
            particlesPosition.set([
                newPosition.x, //0
                newPosition.y, //1
                newPosition.z, //2
            ], i * 3)


            particlesRandomness.set([
                Math.random() * 2 - 1,
                Math.random() * 2 - 1,
                Math.random() * 2 - 1 // -1 <> 1
            ],  i * 3)

        }

        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(particlesPosition, 3))
        particlesGeometry.setAttribute('aRandom', new THREE.BufferAttribute(particlesRandomness, 3))

        //console.log(particlesGeometry)
        const particleMesh = new THREE.Points( particlesGeometry, particlesMaterial );
        const particle2Mesh = new THREE.Points( particlesGeometry, particlesMaterial );
        sceneTwo.add(particleMesh)
        sceneAbout.add(particle2Mesh)
        
        particleMesh.rotation.y = - Math.PI * 0.5
        particleMesh.position.y = 1
        particleMesh.position.x = 0

        particle2Mesh.rotation.y = - Math.PI * 0.5
        particle2Mesh.position.y = 1
        particle2Mesh.position.x = 0.35


    }
)







const gradientgeometry = new THREE.PlaneGeometry(3, 3)

const gradientmaterial = new THREE.ShaderMaterial({
       vertexShader: gradientVertexShader,
       fragmentShader: gradientFragmentShader,
       side: THREE.DoubleSide,
        uniforms:
            {
                uTime: { value: 0 },
                screenSize: {value: new THREE.Vector2(sizes.width, sizes.height)},
                uMouse: { value: new THREE.Vector2(cursor.x, cursor.y) },
            }
})

const plane = new THREE.Mesh( gradientgeometry, gradientmaterial );
scene.add( plane );


//Services Page background Gradient
const servicesgradientmaterial = new THREE.ShaderMaterial({
    vertexShader: servicesVertexShader,
    fragmentShader: servicesFragmentShader,
    side: THREE.DoubleSide,
     uniforms:
         {
             uTime: { value: 0 },
             screenSize: {value: new THREE.Vector2(sizes.width, sizes.height)},
             uMouse: { value: new THREE.Vector2(cursor.x, cursor.y) },
         }
})


const planeServices = new THREE.Mesh( gradientgeometry, servicesgradientmaterial );
sceneServices.add( planeServices );


//plane.quaternion.x += 5.0;





//Particles
const SEPARATION = 0.005, AMOUNTX = 500, AMOUNTY = 50;
let particles, count = 0;

const numParticles = AMOUNTX * AMOUNTY;

const positions = new Float32Array( numParticles * 3 );
				const scales = new Float32Array( numParticles );

				let i = 0, j = 0;

				for ( let ix = 0; ix < AMOUNTX; ix ++ ) {

					for ( let iy = 0; iy < AMOUNTY; iy ++ ) {

						positions[ i ] = ix * SEPARATION - ( ( AMOUNTX * SEPARATION ) / 2 ); // x
						positions[ i + 1 ] = 0; // y
						positions[ i + 2 ] = iy * SEPARATION - ( ( AMOUNTY * SEPARATION ) / 2 ); // z

						//scales[ j ] = 20.0;

						i += 3;
						j ++;

					}

				}

				const particlegeometry = new THREE.BufferGeometry();
				particlegeometry.setAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
				particlegeometry.setAttribute( 'scale', new THREE.BufferAttribute( scales, 1 ) );

				const particlematerial = new THREE.ShaderMaterial( {
                    transparent: true,
					uniforms: {
                        depthWrite: false,
                        blending: THREE.AdditiveBlending,
                        vertexColors: true,
                        
						color: { value: new THREE.Color( 0xffffff ) },
                        uTime: { value: 0 },
                        
                        uBigWavesElevation: { value: 0.2 },
                        uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                        uBigWavesSpeed: { value: 0.75 },
					},
					vertexShader: particlesVertexShader,
					fragmentShader: particlesFragmentShader

				} );

			

				particles = new THREE.Points( particlegeometry, particlematerial );
				scene.add( particles );
                particles.position.z = -0.1;
                particles.quaternion.y = Math.PI * 0.5;

    //const axesHelper = new THREE.AxesHelper( 5 );
    //scene.add( axesHelper );


    // gui.add(particlematerial.uniforms.uBigWavesElevation, 'value').min(0).max(1).step(0.001).name('ParticleBigWavesElevation')
    // gui.add(particlematerial.uniforms.uBigWavesFrequency.value, 'x').min(0).max(10).step(0.001).name('ParticleBigWavesFrequencyX')
    // gui.add(particlematerial.uniforms.uBigWavesFrequency.value, 'y').min(0).max(10).step(0.001).name('ParticleBigWavesFrequencyY')
    // gui.add(particlematerial.uniforms.uBigWavesSpeed, 'value').min(0).max(4).step(0.001).name('ParticleBigWavesSpeed')
    




window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    cameraTwo.aspect = sizes.width / sizes.height
    cameraTwo.updateProjectionMatrix()

    cameraServices.aspect = sizes.width / sizes.height
    cameraServices.updateProjectionMatrix()

    cameraAbout.aspect = sizes.width / sizes.height
    cameraAbout.updateProjectionMatrix()

    // Update renderer
    if(canvas != null) {
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }
    
    // Update renderer
    if(canvasTwo != null) {
    rendererTwo.setSize(sizes.width, sizes.height)
    rendererTwo.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }
    // Update renderer
    if(canvasServices != null) {
    rendererServices.setSize(sizes.width, sizes.height)
    rendererServices.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    if(canvasAbout != null) {
        rendererAbout.setSize(sizes.width, sizes.height)
        rendererAbout.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        }

})

/**
 * Cameras
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, 1)
//camera.position.z = 2
scene.add(camera)


const cameraTwo = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
cameraTwo.position.set(0, 1, 1)
sceneTwo.add(cameraTwo)


const cameraServices = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
cameraServices.position.set(0, 1, 1)
sceneServices.add(cameraServices)


const cameraAbout = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
cameraAbout.position.set(0, 1, 1)
sceneAbout.add(cameraAbout)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true
//

/**
 * Renderer
 */
if(canvas != null) {
   renderer = new THREE.WebGLRenderer({
    canvas: canvas, 
    //alpha: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}


if(canvasTwo != null) {
rendererTwo = new THREE.WebGLRenderer({
    canvas: canvasTwo, 
    alpha: true,
})
rendererTwo.setSize(sizes.width, sizes.height)
rendererTwo.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}


if(canvasServices != null) {
    rendererServices = new THREE.WebGLRenderer({
        canvas: canvasServices, 
        //alpha: true,
})
rendererServices.setSize(sizes.width, sizes.height)
rendererServices.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}


if(canvasAbout != null) {
    rendererAbout = new THREE.WebGLRenderer({
        canvas: canvasAbout, 
        alpha: true,
})
rendererAbout.setSize(sizes.width, sizes.height)
rendererAbout.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}


/**
 * Scroll
 */
//  let scrollY = window.scrollY
//  let currentSection = 0


//  if (window.DeviceOrientationEvent) {
//     window.addEventListener('deviceorientation', deviceOrientationHandler, false);
//     document.getElementById("doeSupported").innerText = "Supported!";
//   } else {
//     document.getElementById("doeSupported").innerText = "notSupported!";
//   }



//  window.addEventListener('scroll', () =>
//  {
//      scrollY = window.scrollY
//      const newSection = Math.round(scrollY / sizes.height)
 
//      gsap.to(
//         camera.rotation,
//         {
//             duration: 1.5,
//             ease: 'power2.inOut',
//             x: '0.25',
//             y: '-1.5',
//             //z: '4',
//         }
//     )


//     gsap.to(   
//             plane.position,
//                 {
//                     duration: 1.5,
//                     ease: 'power2.inOut',
//                     x: '-102',
//                     //y: '0.5',
//                     //z: '2',
//                 }
//         )


//     console.log(camera.position)
    
//  })



/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Water
    //waterMaterial.uniforms.uTime.value = elapsedTime

    particlematerial.uniforms.uTime.value = elapsedTime
    gradientmaterial.uniforms.uMouse.value = cursor
    gradientmaterial.uniforms.uTime.value = elapsedTime



    servicesgradientmaterial.uniforms.uMouse.value = cursor
    servicesgradientmaterial.uniforms.uTime.value = elapsedTime


    particlesMaterial.uniforms.uTime.value = elapsedTime
    particlesMaterial.uniforms.uMouse.value = cursor

    
    if(sceneTwo.children[1]) {
      sceneTwo.children[1].rotation.y += .002
      sceneTwo.children[1].rotation.x += .002
    }

    if(sceneAbout.children[1]) {
        sceneAbout.children[1].rotation.y += .002
        sceneAbout.children[1].rotation.x += .002
      }


    //console.log(camera)
    
    // Update controls
    //controls.update()

    // Render

        if(canvas != null) {
            renderer.render(scene, camera)
        } 

        if(canvasTwo != null) {
        rendererTwo.render(sceneTwo, cameraTwo)
        }

        if(canvasServices != null) {
            rendererServices.render(sceneServices, cameraServices)
            }

            if(canvasAbout != null) {
                rendererAbout.render(sceneAbout, cameraAbout)
        }

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()